<template>
    <div class="order-quantity-container">
      <div class="count-container">
        <div class="txt-color title title-flex">时间段洗车数量</div>
      </div>
      <div ref="plot" class="plot"></div>
    </div>
  </template>
  <script>
  import { Line } from "@antv/g2plot";
  import { format, subDays } from "date-fns";
  export default {
    name: "order-quantity",
    components: {  },
    props: {
      data: {
        type: Array,
        default: () => {
          return [];
        }
      },
      time: {
        type: Array,
        default: () => {
          return [
            format(subDays(new Date(), 7), "yyyy-MM-dd"),
            format(new Date(), "yyyy-MM-dd")
          ];
        }
      }
    },
    data() {
      return {
        plot: null,
      };
    },
    computed: {},
    mounted() {},
    methods: {
      initPieData(list, _data) {
        const plotEl = this.$refs["plot"];
        if (!plotEl) return;
        const data = list;
        const plotConfig = {
            NamedNodeMap: 'Stacked Line2222',
          xField: "label",
          yField: "value",
          seriesField: "name",
          height: 360,
          label: {},
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: true
            }
          },
           tooltip: false,
          tooltip: {
            formatter: datum => {
              return {
                name:datum.name + "洗车量",
                value: datum.value
              };
            }
          },
          legend: {
            position: "top-right"
          }
        };
  
        if (this.plot !== null) {
          this.plot.changeData(data);
        } else {
          this.plot = new Line(plotEl, { ...plotConfig, data });
          this.plot.render();
        }
      }
    }
  };
  </script>
  <style scoped lang="scss">
  .order-quantity-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  
  </style>
  